import React from 'react';
import './style.css';
import { Link } from 'react-router-dom';
import { ROUTE_NAMES } from '../../Routes';

export default function BlogsSection({ blogs }) {
  const handleOpenProjectPage = (link) => {
    if (link) {
      window.open(link, '_blank');
    }
  };
  return (
    <>
      <div className='our-blogs-section'>
        <h2 className='section-title'>News and Blog</h2>
        <div className='blogs-list'>
          {blogs.map((blog, index) => (
            <div
              className='blog-card'
              key={index}
              onClick={() => handleOpenProjectPage(blog.link)}
            >
              <img src={blog.image} alt='blog-pic' className='blog-image' />
              <div className='blog-info'>
                <h3 className='blog-name'>{blog.name}</h3>
                <p className='blog-date'>{blog.date}</p>
              </div>
            </div>
          ))}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20px',
          }}
          className='blogs-btn'
        >
          <Link to={ROUTE_NAMES.BLOG}>
            <button className='bronz-btn'>See more</button>
          </Link>
        </div>
      </div>
    </>
  );
}
