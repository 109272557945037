import React from 'react';
import './style.css';

import MainHeroSectionBG from '../../assets/images/MainHeroSectionBG.webp';
import MainHeroSectionBuildings from '../../assets/images/MainHeroSectionBuildings.webp';

export default function MainHeroSection() {
  return (
    <div className='main-hero-section'>
      <img
        src={MainHeroSectionBG}
        alt='MainHeroSectionBG'
        className='main-hero-sec-bg'
      />
      <div className='main-hero-title'>
        <h1>Starlife group</h1>
      </div>
      <img
        src={MainHeroSectionBuildings}
        alt='MainHeroSectionBuildings'
        className='buildings-cut'
      />
    </div>
  );
}
