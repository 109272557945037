export const ROUTE_NAMES = {
  HOME: '/',
  COMPANY: '/about-us',
  PROJECTS: '/projects',
  TEAM: '/team',
  BLOG: '/blogs',
  CONTACT: '/contact-us',
  INVEST: 'invest-in-project',
  EBSERVICE: '/eb-5',
  STARLIFEPLAZA: '/starlife-plaza',
  HOLLYWOOD: '/hollywood',
  RIVERLANDMANSION: '/riverland-mansion',
  RIVERLANDESTATES: '/riverland-estates',
  CORALRIDGEVILLAS: '/coral-ridge-villas',
  CORALRIDGERESIDENCES: '/coral-ridge-residences',
  DANIABEACHVILLAS: '/dania-beach-villas',
};
