import React from 'react';
import './style.css';

import ImageGallery from 'react-image-gallery';
import { ImLocation } from 'react-icons/im';

export default function ProjectInfoSection({ info }) {
  const paragraphs = info.text.split('\n\n');
  const images = info.images;

  return (
    <div className='project-info-section'>
      <h2 className='service-page-title'>{info.title}</h2>
      {images.length === 1 ? (
        <img
          src={images[0].original}
          alt={info.title}
          className='single-image'
        />
      ) : (
        <ImageGallery
          items={images}
          showNav={false}
          showPlayButton={false}
          autoPlay={false}
        />
      )}
      <h3 className='project-page-location'>
        <ImLocation /> {info.location}
      </h3>
      {paragraphs.map((paragraph, index) => (
        <p className='project-page-info' key={index}>
          {paragraph}
        </p>
      ))}
    </div>
  );
}
