import React from 'react';
import './style.css';

export default function StatisticsSection() {
  return (
    <>
      <div className='statistics-section'>
        <div className='statistics-row'>
          <div className='col-stat col-stat-f'>
            <h2>55 Years</h2>
            <h3>COMBINED YEARS OF EXPERIENCE</h3>
          </div>
          <div className='col-stat'>
            <h2>698,600</h2>
            <h3>TOTAL SQFT</h3>
          </div>
          <div className='col-stat'>
            <h2>278</h2>
            <h3>NUMBER OF UNITS</h3>
          </div>
          <div className='col-stat col-stat-l'>
            <h2>2018</h2>
            <h3>FOUNDED</h3>
          </div>
        </div>
      </div>
    </>
  );
}
