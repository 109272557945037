import React, { useEffect, useState, useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { ROUTE_NAMES } from '../../Routes';
import './style.css';

import { FaBars, FaTimes } from 'react-icons/fa';
import { RiArrowDropDownLine } from 'react-icons/ri';

import MainLogo from '../../assets/images/StarlifeIconWhite.png';
import BronzLogo from '../../assets/images/LogoBronz.png';

export default function Header() {
  const [activeBar, setActiveBar] = useState(true);
  const [showServicesDropdown, setShowServicesDropdown] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const prevScrollPos = useRef(0);
  const location = useLocation();

  useEffect(() => {
    function handleScroll() {
      const currentScrollPos = window.pageYOffset;

      if (currentScrollPos > 400) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }

      prevScrollPos.current = currentScrollPos;
    }

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleBar = () => {
    setActiveBar(!activeBar);
  };

  const isNotHomePage = location.pathname !== ROUTE_NAMES.HOME;

  return (
    <nav
      className={`menu ${scrolled ? 'scrolled' : ''}`}
      style={{
        animationName: scrolled ? 'slideDown' : 'slideUp',
      }}
    >
      <div id='mobile'>
        {activeBar ? (
          <FaBars
            className={`bars ${scrolled || isNotHomePage ? 'notHome' : ''}`}
            onClick={handleBar}
          />
        ) : (
          <FaTimes
            className={`close ${scrolled || isNotHomePage ? 'notHome' : ''}`}
            onClick={handleBar}
          />
        )}
      </div>

      <NavLink to={ROUTE_NAMES.HOME} end>
        {scrolled || isNotHomePage ? (
          <img src={BronzLogo} alt='BronzLogo' id='mainLogo' />
        ) : (
          <img src={MainLogo} alt='MainLogo' id='mainLogo' />
        )}
      </NavLink>

      <ul id='navbar' className={!activeBar ? 'activeMenu' : ''}>
        <li>
          <NavLink
            onClick={handleBar}
            to={ROUTE_NAMES.HOME}
            className={({ isActive }) =>
              `navItem ${isActive ? 'activeNav' : ''} ${
                isNotHomePage || scrolled ? 'notHome' : ''
              }`
            }
            end
          >
            HOME
          </NavLink>
        </li>
        <li>
          <NavLink
            onClick={handleBar}
            to={ROUTE_NAMES.COMPANY}
            className={({ isActive }) =>
              `navItem ${isActive ? 'activeNav' : ''} ${
                isNotHomePage || scrolled ? 'notHome' : ''
              }`
            }
            end
          >
            Company
          </NavLink>
        </li>
        <li
          className='dropdown'
          onMouseEnter={() => setShowServicesDropdown(true)}
          onMouseLeave={() => setShowServicesDropdown(false)}
          onClick={() => setShowServicesDropdown(!showServicesDropdown)}
        >
          <NavLink
            className={({ isActive }) =>
              `navItem servicesNav ${isActive ? 'activeNav' : ''} ${
                isNotHomePage || scrolled ? 'notHome' : ''
              }`
            }
            end
          >
            Services <RiArrowDropDownLine className='small-dropdown-icon' />
          </NavLink>

          {showServicesDropdown && (
            <ul className='dropdownMenu-services'>
              <li>
                <NavLink className={'navItem-secondary'} onClick={handleBar}>
                  Real Estate Development
                </NavLink>
              </li>
              <li>
                <NavLink className={'navItem-secondary'} onClick={handleBar}>
                  Construction Management
                </NavLink>
              </li>
              <li>
                <NavLink className={'navItem-secondary'} onClick={handleBar}>
                  Investment and Capital Ventures
                </NavLink>
              </li>
              <li>
                <NavLink className={'navItem-secondary'} onClick={handleBar}>
                  Property and Asset Management
                </NavLink>
              </li>
              <li>
                <NavLink className={'navItem-secondary'} onClick={handleBar}>
                  Architectural Design and Planning
                </NavLink>
              </li>
              <li>
                <NavLink className={'navItem-secondary'} onClick={handleBar}>
                  Market Research and Feasibility Studies
                </NavLink>
              </li>
            </ul>
          )}
        </li>
        <li>
          <NavLink
            onClick={handleBar}
            to={ROUTE_NAMES.PROJECTS}
            className={({ isActive }) =>
              `navItem ${isActive ? 'activeNav' : ''} ${
                isNotHomePage || scrolled ? 'notHome' : ''
              }`
            }
            end
          >
            Projects
          </NavLink>
        </li>
        <li>
          <NavLink
            onClick={handleBar}
            to={ROUTE_NAMES.TEAM}
            className={({ isActive }) =>
              `navItem ${isActive ? 'activeNav' : ''} ${
                isNotHomePage || scrolled ? 'notHome' : ''
              }`
            }
            end
          >
            Team
          </NavLink>
        </li>
        <li>
          <NavLink
            onClick={handleBar}
            to={ROUTE_NAMES.BLOG}
            className={({ isActive }) =>
              `navItem ${isActive ? 'activeNav' : ''} ${
                isNotHomePage || scrolled ? 'notHome' : ''
              }`
            }
            end
          >
            Blogs
          </NavLink>
        </li>
        {/* <li>
          <NavLink
            onClick={handleBar}
            to={ROUTE_NAMES.INVEST}
            className={({ isActive }) =>
              `navItem ${isActive ? 'activeNav' : ''} ${
                isNotHomePage || scrolled ? 'notHome' : ''
              }`
            }
            end
          >
            Invest in project
          </NavLink>
        </li> */}
        <li>
          <NavLink
            onClick={handleBar}
            to={ROUTE_NAMES.CONTACT}
            className={({ isActive }) =>
              `navItem ${isActive ? 'activeNav' : ''} ${
                isNotHomePage || scrolled ? 'notHome' : ''
              }`
            }
            end
          >
            Contact us
          </NavLink>
        </li>
      </ul>

      <div className='quality-banner'>
        <h3>QUALITY YOU CAN TRUST</h3>
      </div>
    </nav>
  );
}
