import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './style.css';
import { IoMdPlay } from 'react-icons/io';

import Cover_1 from '../../assets/images/glimps-1.webp';
import Cover_2 from '../../assets/images/glimps-2.webp';
import Cover_3 from '../../assets/images/glimps-3.webp';
import Cover_4 from '../../assets/images/glimps-4.webp';
import Cover_5 from '../../assets/images/glimps-5.webp';
import Cover_6 from '../../assets/images/glimps-6.webp';

export default function GlimpsSection() {
  const [playingIndex, setPlayingIndex] = useState(null);

  const handlePlay = (index) => {
    setPlayingIndex(index);
  };

  const videos = [
    {
      src: 'https://pub-49d97e77b0ae42d0a47ad8443433d0fb.r2.dev/Glimpse-1.mp4',
      cover: Cover_1,
    },
    {
      src: 'https://pub-49d97e77b0ae42d0a47ad8443433d0fb.r2.dev/Glimpse-2.mp4',
      cover: Cover_2,
    },
    {
      src: 'https://pub-49d97e77b0ae42d0a47ad8443433d0fb.r2.dev/Glimpse-3.mp4',
      cover: Cover_3,
    },
    {
      src: 'https://pub-49d97e77b0ae42d0a47ad8443433d0fb.r2.dev/Glimpse-4.mp4',
      cover: Cover_4,
    },
    {
      src: 'https://pub-49d97e77b0ae42d0a47ad8443433d0fb.r2.dev/Glimpse-5.mp4',
      cover: Cover_5,
    },
    {
      src: 'https://pub-49d97e77b0ae42d0a47ad8443433d0fb.r2.dev/Glimpse-6.mp4',
      cover: Cover_6,
    },
  ];

  const settings = {
    className: 'center',
    centerMode: true,
    dots: true,
    infinite: true,
    focusOnSelect: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 4000,
    cssEase: 'linear',
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: 3, slidesToScroll: 1, dots: false },
      },
      {
        breakpoint: 600,
        settings: { slidesToShow: 2, slidesToScroll: 1, initialSlide: 2 },
      },
      { breakpoint: 500, settings: { slidesToShow: 1, slidesToScroll: 1 } },
    ],
  };

  return (
    <div>
      <div className='glimpse-section'>
        <div className='col-small-2'>
          <h2>Glimpse into starlife</h2>
        </div>
        <div className='bronz-box'></div>
      </div>

      <div className='video-slider'>
        <Slider {...settings}>
          {videos.map((video, index) => (
            <div key={index} className='slider-col'>
              {playingIndex === index ? (
                <video
                  src={video.src}
                  controls
                  autoPlay
                  className='video-player'
                  onPause={() => setPlayingIndex(null)}
                />
              ) : (
                <div className='video-cover' onClick={() => handlePlay(index)}>
                  <img
                    src={video.cover}
                    alt={`Video Cover ${index + 1}`}
                    className='cover-image'
                  />
                  <button
                    className='play-button'
                    aria-label={`Play video ${index + 1}`}
                  >
                    <IoMdPlay />
                  </button>
                </div>
              )}
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}
