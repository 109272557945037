import React, { useState } from 'react';

import PDF from '../../assets/documents/21 Hollywood Presentation.pdf';

import { SendForm } from '../../Platform/SendForm';

export default function DownloadHollywoodPDFSection() {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
    if (error) {
      setError('');
    }
  };

  const downloadResume = () => {
    const fileUrl = PDF;
    const fileName = '21 Hollywood Presentation';
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileName;
    link.click();
  };

  const handleSubmit = async () => {
    const { fullName, email } = formData;

    if (!fullName.trim() || !email.trim()) {
      setError('Please fill out all fields.');
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    let data = {
      Name: formData.fullName,
      Mail: formData.email,
      Origin: '21 Hollywood Project',
    };

    try {
      await SendForm(data);
      setSuccess(true);
      downloadResume();
      setError('');
    } catch (error) {}
  };

  return (
    <div className='download-pdf-section'>
      <h2 className='section-title'>Download the complete information</h2>
      <div className='form-section'>
        <div className='input-group'>
          <input
            id='fullName2'
            type='text'
            name='fullName'
            value={formData.fullName}
            placeholder='Full name'
            onChange={handleChange}
          />
        </div>
        <div className='input-group'>
          <input
            id='email2'
            type='email'
            name='email'
            value={formData.email}
            placeholder='Email'
            onChange={handleChange}
          />
        </div>
      </div>
      <div
        style={{
          display: 'grid',
          justifyContent: 'center',
          marginBottom: '5em',
        }}
      >
        {error && <p className='error-message'>{error}</p>}
        {success && (
          <p className='success-message'>
            Form successfully submitted, we will contact you
          </p>
        )}
        <button className='form-submit-btn' onClick={handleSubmit}>
          Submit
        </button>
      </div>
    </div>
  );
}
