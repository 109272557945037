import React, { useState, useEffect, useRef } from 'react';
import './style.css';

import AboutUsCover from '../../assets/images/AboutUsCover.webp';

import { IoMdPlay } from 'react-icons/io';

export default function AboutUsSection() {
  const [isPlaying, setIsPlaying] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const currentSectionRef = sectionRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          // Start playing the video when the section is in view
          setIsPlaying(true);
        } else {
          setIsPlaying(false);
        }
      },
      {
        threshold: 0.5,
      }
    );

    if (currentSectionRef) {
      observer.observe(currentSectionRef);
    }

    return () => {
      if (currentSectionRef) {
        observer.unobserve(currentSectionRef);
      }
    };
  }, []);

  const handlePlay = () => {
    setIsPlaying(true);
  };

  return (
    <div className='about-us-section' ref={sectionRef}>
      <h2 className='section-title'>About Starllife Group</h2>
      <div className='row-section'>
        <div className='col-1'>
          <p>
            Founded by visionary leader Gevorg Shahbazyan, Starlife Group has
            established itself as a pioneer in real estate development and
            investment. Our expertise spans residential and commercial
            developments, as well as comprehensive asset management services. We
            are dedicated to transforming spaces and communities through
            strategic planning and innovative design.
          </p>
        </div>
        <div className='col-2'>
          {isPlaying ? (
            <video
              src={
                'https://pub-49d97e77b0ae42d0a47ad8443433d0fb.r2.dev/about-us.mp4'
              }
              controls
              autoPlay
              className='video-player'
            />
          ) : (
            <div className='video-cover' onClick={handlePlay}>
              <img
                src={AboutUsCover}
                alt='Video Cover'
                className='cover-image'
              />
              <button className='play-button'>
                <IoMdPlay />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
