import axios from 'axios';
 
export const SendForm = (data) => {
  return axios.post(
    'https://n81f4h9238.execute-api.us-east-1.amazonaws.com/v1',
    data,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};
