import React from 'react';
import './style.css';

export default function OurLocationSection() {
  return (
    <div className='our-location'>
      <div className='row-section'>
        <div className='col-small'>
          <h2>
            Our Location: <span> Miami, US</span>
          </h2>
          <p>
            Nestled in the vibrant heart of Miami, Starlife Group thrives in one
            of the world's most dynamic and sought-after real estate markets.
            Miami is more than just our home base; it's a hub for international
            business, culture, and luxury living.
          </p>

          <p>
            This city’s booming economy, strategic location as a gateway between
            the Americas, and unmatched lifestyle oerings have solidified it as
            a magnet for global investment.
          </p>

          <p>
            Renowned for its iconic skyline, pristine beaches, and a climate
            that promotes outdoor living year-round, Miami embodies growth and
            opportunity. The city's real estate market attracts a diverse range
            of international investors seeking high-value developments, making
            it the ideal landscape for Starlife Group to expand and innovate.
          </p>
        </div>
        <div className='bronz-box'></div>
      </div>
    </div>
  );
}
