import React from 'react';
import './style.css';

import { BsWhatsapp } from 'react-icons/bs';

export default function GetInTouchSection() {
  return (
    <>
      <div className='get-in-touch-section'>
        <div className='get-in-touch-row'>
          <div className='col-l-small'>
            <h2>Get in touch today</h2>
          </div>
          <div className='col-r-small'>
            <a
              href='https://wa.me/13059157737'
              target='_blank'
              rel='noopener noreferrer'
            >
              <BsWhatsapp className='icon' />
            </a>
            <a href='tel:+13059157737'>
              <h3>+1 305 915 7737</h3>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
