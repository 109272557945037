import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ROUTE_NAMES } from "./Routes";
import Layout from "./Pages/Layout";
import Home from "./Pages/Home";
import AboutUs from "./Pages/AboutUs";
import Team from "./Pages/Team";
import Projects from "./Pages/Projects";
import Blogs from "./Pages/Blogs";
import ContactUs from "./Pages/ContactUs";
import EBService from "./Pages/EBService";
import InvestInProject from "./Pages/InvestPage";

//project pages
import HollywoodProject from "./Pages/ProjectsPagesGrouped/HollywoodProject";
import StarlifePlazaProject from "./Pages/ProjectsPagesGrouped/StarlifePlazaProject";
import RiverlandMansionProject from "./Pages/ProjectsPagesGrouped/RiverlandMansion";
import RiverlandEstatesProject from "./Pages/ProjectsPagesGrouped/RiverlandEstates";
import CoralRidgeVillasProject from "./Pages/ProjectsPagesGrouped/CoralRidgeVillas";
import CoralRidgeResidencesProject from "./Pages/ProjectsPagesGrouped/CoralRidgeResidences";
import DaniaBeachVillasProject from "./Pages/ProjectsPagesGrouped/DaniaBeachVillas";

function App() {
  return (
    <BrowserRouter>
      <>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path={ROUTE_NAMES.HOME} element={<Home />} />
            <Route path={ROUTE_NAMES.COMPANY} element={<AboutUs />} />
            <Route path={ROUTE_NAMES.TEAM} element={<Team />} />
            <Route path={ROUTE_NAMES.PROJECTS} element={<Projects />} />
            <Route path={ROUTE_NAMES.BLOG} element={<Blogs />} />
            <Route path={ROUTE_NAMES.CONTACT} element={<ContactUs />} />
            <Route path={ROUTE_NAMES.INVEST} element={<InvestInProject />} />
            <Route path={ROUTE_NAMES.EBSERVICE} element={<EBService />} />

            {/* project pages */}
            <Route path={ROUTE_NAMES.HOLLYWOOD} element={<HollywoodProject />} />
            <Route path={ROUTE_NAMES.STARLIFEPLAZA} element={<StarlifePlazaProject />} />
            <Route path={ROUTE_NAMES.RIVERLANDMANSION} element={<RiverlandMansionProject />} />
            <Route path={ROUTE_NAMES.RIVERLANDESTATES} element={<RiverlandEstatesProject />} />
            <Route path={ROUTE_NAMES.CORALRIDGEVILLAS} element={<CoralRidgeVillasProject />} />
            <Route path={ROUTE_NAMES.CORALRIDGERESIDENCES} element={<CoralRidgeResidencesProject />} />
            <Route path={ROUTE_NAMES.DANIABEACHVILLAS} element={<DaniaBeachVillasProject />} />

            <Route path="*" element={<Home />} />
          </Route>
        </Routes>
      </>
    </BrowserRouter>
  );
}

export default App;
