import React, { useEffect } from 'react';
import './style.css';

import ContactUsSection from '../../Components/ContactUsSection';

export default function ContactUs() {
  useEffect(() => {
    document.title = 'Starlife: Contact Us';
  }, []);
  return (
    <div className='contact-us-page'>
      <ContactUsSection />
    </div>
  );
}
