import React from 'react';
import './style.css';

export default function OurMissionSection() {
  return (
    <div className='our-mission-section'>
      <h2 className='section-title'>Our Mission</h2>
      <div className='row-section'>
        <p>
          At Starlife Group, our mission is to reshape urban landscapes with
          innovative real estate solutions that harmonize modern living with
          sustainability and community growth. We strive to be the most trusted
          partner in development, leveraging our expertise and global
          partnerships to deliver projects that inspire and enhance the quality
          of life for residents, investors, and stakeholders alike. We aim to
          build lasting value through commitment, transparency, and world-class
          service.
        </p>
      </div>
    </div>
  );
}
