import React, { useEffect } from 'react';
import './style.css';

import { ROUTE_NAMES } from '../../Routes';

import OurProjectsSection from '../../Components/OurProjectsSection';
import GetInTouchSection from '../../Components/GetInTouchSection';
import ContactUsSection from '../../Components/ContactUsSection';

// Project Pics
import P_1 from '../../assets/images/Starlife-Plaza-1.jpg';
import P_2 from '../../assets/images/Hollywood-P-1.jpg';
import P_3 from '../../assets/images/Mansion-Riverland-P-1.jpg';
import P_4 from '../../assets/images/Estates-Riverland-P-1.jpg';
import P_5 from '../../assets/images/Coral-Ridge-P-1.jpg';
import P_6 from '../../assets/images/Coral-Ridge-Residenc-P-1.jpg';
import P_7 from '../../assets/images/Dania-Beach-P-1.jpg';

export default function Projects() {
  useEffect(() => {
    document.title = 'Starlife: Projects';

    const keywordsContent =
      'Sustainable luxury buildings, Custom-built commercial properties, Smart home and office solutions, Corporate office spaces for lease, Premium residential projects, Starlife real estate projects';
    let metaKeywords = document.querySelector('meta[name="keywords"]');

    if (!metaKeywords) {
      metaKeywords = document.createElement('meta');
      metaKeywords.name = 'keywords';
      document.head.appendChild(metaKeywords);
    }

    metaKeywords.setAttribute('content', keywordsContent);
  }, []);

  const projects = [
      {
        project_name: 'Starlife Plaza',
        price: '$1.700.000 - $21.900.000',
        location: 'Yerevan, Armenia ',
        pic: P_1,
        link: ROUTE_NAMES.STARLIFEPLAZA,
      },
      {
        project_name: '21 Hollywood',
        price: '$1.700.000 - $21.900.000',
        location: '2100 N Federal Hwy, Hollywood, FL, 33020',
        pic: P_2,
        link: ROUTE_NAMES.HOLLYWOOD,
      },
      {
        project_name: 'The Mansions at Riverland',
        price: '$1.700.000 - $21.900.000',
        location: '1955 SW 29th Ave, Fort Lauderdale, FL, 33312',
        pic: P_3,
        link: ROUTE_NAMES.RIVERLANDMANSION,
      },
      {
        project_name: 'The Estates at Riverland',
        price: '$1.700.000 - $21.900.000',
        location: '1841 SW 29th Ave, Fort Lauderdale, FL, 33312',
        pic: P_4,
        link: ROUTE_NAMES.RIVERLANDESTATES,
      },
      {
        project_name: 'The Villas at Coral Ridge',
        price: '$1.700.000 - $21.900.000',
        location: '1825 NE 26th Ave, Fort Lauderdale, FL, 33305',
        pic: P_5,
        link: ROUTE_NAMES.CORALRIDGEVILLAS,
      },
      {
        project_name: 'The Residences at Coral Ridge',
        price: '$1.700.000 - $21.900.000',
        location: '1521 NE 26th Ave, Fort Lauderdale, FL, 33304',
        pic: P_6,
        link: ROUTE_NAMES.CORALRIDGERESIDENCES,
      },
      {
        project_name: 'The Villas at Dania Beach',
        price: '$1.700.000 - $21.900.000',
        location: '20 SW 15th St, Dania Beach, FL, 33004',
        pic: P_7,
        link: ROUTE_NAMES.DANIABEACHVILLAS,
      },
    ];
  return (
    <div className='projects-page'>
      <OurProjectsSection projects={projects} />
      <GetInTouchSection />
      <ContactUsSection />
    </div>
  );
}
