import React from 'react';
import './style.css';

import { Link } from 'react-router-dom';
import { ROUTE_NAMES } from '../../Routes';

import { ImLocation } from 'react-icons/im';
import { TfiArrowRight } from 'react-icons/tfi';

export default function OurProjectsSection({ projects }) {
  const handleOpenProjectPage = (link) => {
    if (link) {
      window.open(link, '_blank');
    }
  };

  return (
    <>
      <div className='our-projects-section'>
        <h2 className='section-title'>Our Projects</h2>
      </div>
      <div className='projects-list'>
        {projects.map((project, index) => (
          <div
            className='project-card'
            key={index}
            onClick={() => handleOpenProjectPage(project.link)}
          >
            <img
              src={project.pic}
              alt='project-pic'
              className='project-image'
            />
            <div className='project-info'>
              <h3 className='project-name'>{project.project_name}</h3>
              {/* <p className='project-price'>
                Price Range: <span>{project.price}</span>
              </p> */}
              <p className='project-location'>
                <ImLocation /> {project.location}
              </p>
              <button className='project-arrow-btn'>
                <TfiArrowRight />
              </button>
            </div>
          </div>
        ))}
      </div>
      <div
        style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
        className='projects-btn'
      >
        <Link to={ROUTE_NAMES.PROJECTS}>
          <button className='bronz-btn'>See more</button>
        </Link>
      </div>
    </>
  );
}
