import React from 'react';
import './style.css';

import Gevorg from '../../assets/images/Gevorg.webp';
import Arman from '../../assets/images/Arman.webp';

export default function OurFoundersSection() {
  return (
    <>
      <div className='our-founders-section'>
        <div className='founders-inner-sec'>
          <h2 className='section-title'>Our Founders</h2>
          <div className='row'>
            <div className='col-l-1'>
              <img src={Gevorg} alt='Gevorg' />
              <h3 className='founder-name'>GEVORG SHAHBAZYAN</h3>
              <h4 className='founder-position'>
                Founder and CEO of Starlife Group and Companies
              </h4>
            </div>
            <div className='col-r-1'>
              <p>
                Gevorg Shahbazyan is the Founder and CEO of Starlife Group and
                Companies, a premier real estate development firm based in South
                Florida. With over a decade of experience in the real estate
                industry as a developer and broker associate, Gevorg has been
                instrumental in shaping Miami's real estate landscape. He holds
                a Master's degree in Global Aairs from Florida International
                University and is the author of Inside the Mind of a Real Estate
                Developer, sharing his extensive knowledge and insights. His
                hands-on involvement in every phase of Starlife's projects has
                honed his expertise in real estate development and construction.
              </p>
              <p>
                Under his leadership, Starlife Group has evolved into a thriving
                organization, comprising Starlife Builders, Starlife Capital
                Ventures, and Starlife International Realty. The company is
                actively involved in large-scale multifamily and mixed-use
                development projects across South Florida, as well as commercial
                developments in Europe. With a portfolio of over 75 townhomes
                and a team of professionals with over 55 years of combined
                experience, Starlife Group has built a solid reputation for
                excellence and innovation in real estate.
              </p>
            </div>
          </div>
          <div className='row'>
            <div className='col-l-1'>
              <img src={Arman} alt='Arman' />
              <h3 className='founder-name'>ARMAN SHAHBAZYAN</h3>
              <h4 className='founder-position'>
                Co-Founder of Starlife Group and Companies
              </h4>
            </div>
            <div className='col-r-1'>
              <p>
                Arman Shahbazyan is the Co-Founder of Starlife Group and
                Companies, where he plays a pivotal role in leading the firm's
                construction and operational activities. With over a decade of
                experience in real estate, Arman brings a wealth of expertise to
                his position as a licensed General Contractor in Florida. He is
                also a licensed Mortgage Broker and Real Estate Agent, enhancing
                his broad understanding of the industry. A graduate of Florida
                International University with a Master's in Criminal Justice,
                Arman has also earned multiple project management and team
                management certifications from Harvard University. His
                leadership ensures that every Starlife project is executed with
                precision and excellence, creating a strong foundation for
                investor confidence.
              </p>
              <p>
                As the General Contractor for Starlife Builders, Arman leads a
                team of Senior Construction Project Managers, VPs of Operations,
                engineers, superintendents, and other skilled professionals. He
                oversees Starlife Group's diverse portfolio, including
                residential, commercial, and mixed-use developments across
                Florida. Prior to joining Starlife, Arman's leadership at
                American Airlines gave him invaluable experience in managing
                large-scale operations involving thousands of employees. His
                strategic insights and operational expertise make him an
                essential leader in the growth and success of Starlife's
                projects, including the EB-5 qualified 21 Hollywood project.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
