import React, { useEffect } from 'react';

import ProjectInfoSection from '../../../Components/ProjectInfoSection';
import GetInTouchSection from '../../../Components/GetInTouchSection';
import ContactUsSection from '../../../Components/ContactUsSection';

import OurProjectsSection from '../../../Components/OurProjectsSection';

import { ROUTE_NAMES } from '../../../Routes';

// Project Pics
import P_1 from '../../../assets/images/Starlife-Plaza-1.jpg';
import P_2 from '../../../assets/images/Hollywood-P-1.jpg';
import P_3 from '../../../assets/images/Mansion-Riverland-P-1.jpg';
// import P_4 from '../../../assets/images/Estates-Riverland-P-1.jpg';
import P_5 from '../../../assets/images/Coral-Ridge-P-1.jpg';
import P_6 from '../../../assets/images/Coral-Ridge-Residenc-P-1.jpg';
import P_7 from '../../../assets/images/Dania-Beach-P-1.jpg';

import EstateRiverland_1 from '../../../assets/images/Estates-Riverland-P-1.jpg';
import EstateRiverland_2 from '../../../assets/images/Estates-Riverland-P-2.jpg';

export default function RiverlandEstatesProject() {
  useEffect(() => {
    document.title = 'Starlife: The Estates at Riverland - 11 Townhomes';
    const titleElement = document.querySelector(
      '.our-projects-section .section-title'
    );
    if (titleElement) {
      titleElement.innerHTML = 'Other Projects';
    }
  }, []);
  const info = {
    title:
      'The Estates at Riverland: Modern Minimalist Luxury in Fort Lauderdale',
    price: '$1.700.000 - $21.900.000',
    location: '1841 SW 29th Ave, Fort Lauderdale, FL, 33312',
    images: [
      {
        original: EstateRiverland_1,
        thumbnail: EstateRiverland_1,
      },
      {
        original: EstateRiverland_2,
        thumbnail: EstateRiverland_2,
      },
    ],
    text: `Welcome to The Estates at Riverland, a private and exclusive community of luxury townhomes, designed to elevate modern living with natural elegance and simplicity. Situated in the dynamic area of Fort Lauderdale, FL, this project offers a sophisticated retreat, blending contemporary design with a minimalist aesthetic that is perfect for those seeking an upscale yet understated lifestyle.

Inspired by the principles of open space and refined luxury, each two-story townhouse spans between 2,500 to 3,000 square feet and features four spacious bedrooms, providing residents with generous living areas. The design of The Estates at Riverland embraces clean lines and natural materials, creating an atmosphere of tranquility and modern comfort. Every detail, from the use of light and space to the selection of high-end finishes, is thoughtfully curated to ensure a harmonious living experience.
Each residence comes complete with two car spaces, a private pool, a patio, and a BBQ area, offering the perfect setting for relaxation and outdoor entertainment. This exclusive community is designed for young professionals, families, and couples who value both privacy and connectivity to the vibrant surroundings of Fort Lauderdale.
Though distinct from the Tulum-inspired vibes of its counterpart, The Mansions at Riverland, The Estates at Riverland offers its own interpretation of luxury—rooted in modern design, natural materials, and the promise of a peaceful, elegant lifestyle.

This community represents the essence of refined simplicity, where each townhome is an expression of modern luxury in one of Fort Lauderdale's most exciting neighborhoods.
`,
  };
  const projects = [
    {
      project_name: 'Starlife Plaza',
      price: '$1.700.000 - $21.900.000',
      location: 'Yerevan, Armenia ',
      pic: P_1,
      link: ROUTE_NAMES.STARLIFEPLAZA,
    },
    {
      project_name: '21 Hollywood',
      price: '$1.700.000 - $21.900.000',
      location: '2100 N Federal Hwy, Hollywood, FL, 33020',
      pic: P_2,
      link: ROUTE_NAMES.HOLLYWOOD,
    },
    {
      project_name: 'The Mansions at Riverland',
      price: '$1.700.000 - $21.900.000',
      location: '1955 SW 29th Ave, Fort Lauderdale, FL, 33312',
      pic: P_3,
      link: ROUTE_NAMES.RIVERLANDMANSION,
    },
    // {
    //   project_name: 'The Estates at Riverland',
    //   price: '$1.700.000 - $21.900.000',
    //   location: '1841 SW 29th Ave, Fort Lauderdale, FL, 33312',
    //   pic: P_4,
    //   link: ROUTE_NAMES.RIVERLANDESTATES,
    // },
    {
      project_name: 'The Villas at Coral Ridge',
      price: '$1.700.000 - $21.900.000',
      location: '1825 NE 26th Ave, Fort Lauderdale, FL, 33305',
      pic: P_5,
      link: ROUTE_NAMES.CORALRIDGEVILLAS,
    },
    {
      project_name: 'The Residences at Coral Ridge',
      price: '$1.700.000 - $21.900.000',
      location: '1521 NE 26th Ave, Fort Lauderdale, FL, 33304',
      pic: P_6,
      link: ROUTE_NAMES.CORALRIDGERESIDENCES,
    },
    {
      project_name: 'The Villas at Dania Beach',
      price: '$1.700.000 - $21.900.000',
      location: '20 SW 15th St, Dania Beach, FL, 33004',
      pic: P_7,
      link: ROUTE_NAMES.DANIABEACHVILLAS,
    },
  ];

  return (
    <div className='single-project-page'>
      <ProjectInfoSection info={info} />
      <div
        className='map-section'
        style={{ marginTop: '5em', textAlign: 'center' }}
      >
        <iframe
          title='Google Map Location'
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3583.0211171866135!2d-80.18821202101263!3d26.09822933570162!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d900b249d59519%3A0x2c2239986ab6cc31!2zMTg0MSBTVyAyOXRoIEF2ZSwgRm9ydCBMYXVkZXJkYWxlLCBGTCAzMzMxMiwg0KHQqNCQ!5e0!3m2!1sru!2sam!4v1743147542362!5m2!1sru!2sam'
          width='100%'
          height='400'
          style={{ border: 0 }}
          allowFullScreen=''
          loading='lazy'
          referrerPolicy='no-referrer-when-downgrade'
        ></iframe>
      </div>

      <OurProjectsSection projects={projects} />
      <GetInTouchSection />
      <ContactUsSection />
    </div>
  );
}
