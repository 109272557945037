import React, { useEffect } from 'react';

import InvestSection from '../../Components/InvestSection';

export default function InvestInProject() {
  useEffect(() => {
    document.title = 'Starlife: Invest in Project';
  }, []);
  return (
    <div className='contact-us-page'>
      <InvestSection/>
    </div>
  );
}
