import React, { useEffect } from 'react';

import { ROUTE_NAMES } from '../../Routes';

import MainHeroSection from '../../Components/MainHeroSection';
import AboutUsSection from '../../Components/AboutUsSection';
import OurLocationSection from '../../Components/OurLocationSection';
import OurMissionSection from '../../Components/OurMissionSection';
import OurProjectsSection from '../../Components/OurProjectsSection';
import GlimpsSection from '../../Components/GlimpsSection';
import OurFoundersSection from '../../Components/OurFounders';
import ServicesSection from '../../Components/ServicesSection';
import BlogsSection from '../../Components/BlogsSection';
import StatisticsSection from '../../Components/StatisticsSection';
import GetInTouchSection from '../../Components/GetInTouchSection';
import ContactUsSection from '../../Components/ContactUsSection';

// Project Pics
import P_1 from '../../assets/images/Starlife-Plaza-1.jpg';
import P_2 from '../../assets/images/Hollywood-P-1.jpg';
import P_3 from '../../assets/images/Mansion-Riverland-P-1.jpg';
import P_4 from '../../assets/images/Estates-Riverland-P-1.jpg';
import P_5 from '../../assets/images/Coral-Ridge-P-1.jpg';
import P_6 from '../../assets/images/Coral-Ridge-Residenc-P-1.jpg';
// import P_7 from '../../assets/images/Dania-Beach-P-1.jpg';

export default function Home() {
  useEffect(() => {
    document.title = 'Starlife: Quality you can trust';

    const keywordsContent =
      'Luxury real estate, High-end property development, Premium commercial properties, Modern real estate solutions, High-return real estate investments, Innovative property development, Luxury developments by Starlife Group, Best real estate company in Miami, Top commercial real estate firm';

    let metaKeywords = document.querySelector('meta[name="keywords"]');

    if (!metaKeywords) {
      metaKeywords = document.createElement('meta');
      metaKeywords.name = 'keywords';
      document.head.appendChild(metaKeywords);
    }

    metaKeywords.setAttribute('content', keywordsContent);
  }, []);

  const projects = [
    {
      project_name: 'Starlife Plaza',
      price: '$1.700.000 - $21.900.000',
      location: 'Yerevan, Armenia ',
      pic: P_1,
      link: ROUTE_NAMES.STARLIFEPLAZA,
    },
    {
      project_name: '21 Hollywood',
      price: '$1.700.000 - $21.900.000',
      location: '2100 N Federal Hwy, Hollywood, FL, 33020',
      pic: P_2,
      link: ROUTE_NAMES.HOLLYWOOD,
    },
    {
      project_name: 'The Mansions at Riverland',
      price: '$1.700.000 - $21.900.000',
      location: '1955 SW 29th Ave, Fort Lauderdale, FL, 33312',
      pic: P_3,
      link: ROUTE_NAMES.RIVERLANDMANSION,
    },
    {
      project_name: 'The Estates at Riverland',
      price: '$1.700.000 - $21.900.000',
      location: '1841 SW 29th Ave, Fort Lauderdale, FL, 33312',
      pic: P_4,
      link: ROUTE_NAMES.RIVERLANDESTATES,
    },
    {
      project_name: 'The Villas at Coral Ridge',
      price: '$1.700.000 - $21.900.000',
      location: '1825 NE 26th Ave, Fort Lauderdale, FL, 33305',
      pic: P_5,
      link: ROUTE_NAMES.CORALRIDGEVILLAS,
    },
    {
      project_name: 'The Residences at Coral Ridge',
      price: '$1.700.000 - $21.900.000',
      location: '1521 NE 26th Ave, Fort Lauderdale, FL, 33304',
      pic: P_6,
      link: ROUTE_NAMES.CORALRIDGERESIDENCES,
    },
    // {
    //   project_name: 'The Villas at Dania Beach',
    //   price: '$1.700.000 - $21.900.000',
    //   location: '20 SW 15th St, Dania Beach, FL, 33004',
    //   pic: P_7,
    //   link: ROUTE_NAMES.DANIABEACHVILLAS,
    // },
  ];

  const blogs = [
    {
      name: 'Blog',
      date: '25 March, 2025',
      image: P_1,
      link: '',
    },
    {
      name: 'Blog',
      date: '23 March, 2025',
      image: P_2,
      link: '',
    },
    {
      name: 'Blog',
      date: '21 March, 2025',
      image: P_3,
      link: '',
    },
    {
      name: 'Blog',
      date: '24 March, 2025',
      image: P_4,
      link: '',
    },
    {
      name: 'Blog',
      date: '22 March, 2025',
      image: P_5,
      link: '',
    },
    {
      name: 'Blog',
      date: '20 March, 2025',
      image: P_6,
      link: '',
    },
  ];

  return (
    <div className='home-page'>
      <MainHeroSection />
      <AboutUsSection />
      <OurLocationSection />
      <OurMissionSection />
      <OurProjectsSection projects={projects} />
      <GlimpsSection />
      <OurFoundersSection />
      <ServicesSection />
      <BlogsSection blogs={blogs} />
      <StatisticsSection />
      <GetInTouchSection />
      <ContactUsSection />
    </div>
  );
}
