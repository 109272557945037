import React from 'react';
import './style.css';
import { Link } from 'react-router-dom';
import { ROUTE_NAMES } from '../../Routes';

import RightArrow from '../../assets/images/right-arrow.png';

export default function ServicesSection() {
  return (
    <>
      <div className='our-services-section'>
        <h2 className='section-title'>Services and affiliated companies</h2>
        <div className='service-list'>
          <div className='service-block green-block'>
            <h3>Development</h3>
            <button className='right-arrow-btn'>
              <img src={RightArrow} alt='right-arrow' />
            </button>
          </div>
          <div className='service-block'>
            <h3>Construction</h3>
            <button className='right-arrow-btn'>
              <img src={RightArrow} alt='right-arrow' />
            </button>
          </div>
          <div className='service-block green-block'>
            <h3>Real Estate Brokerage & Mortgage Services</h3>
            <button className='right-arrow-btn'>
              <img src={RightArrow} alt='right-arrow' />
            </button>
          </div>
          <div className='service-block'>
            <h3>Capital venture</h3>
            <button className='right-arrow-btn'>
              <img src={RightArrow} alt='right-arrow' />
            </button>
          </div>
          <Link
            to={ROUTE_NAMES.EBSERVICE}
            className='service-block green-block'
          >
            <h3>EB-5 US Residency through Investment</h3>
            <button className='right-arrow-btn' style={{ cursor: 'pointer' }}>
              <img src={RightArrow} alt='right-arrow' />
            </button>
          </Link>
        </div>
      </div>
    </>
  );
}
